import { Skeleton } from "components/ui/skeleton";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getMainProjectsByMainProjectId } from "client";
import PublicPageWrapper from "components/PublicPageWrapper";
import ProjectAppView from "components/ProjectAppView";

function MainProjectAppPage() {
  const navigate = useNavigate();
  const { mainProjectId } = useParams();
  const [projectId, setProjectId] = useState<string | undefined>();

  useEffect(() => {
    load();
  }, [mainProjectId]);

  const load = async () => {
    if (!mainProjectId) return;

    const res = await getMainProjectsByMainProjectId({
      path: {
        main_project_id: mainProjectId,
      },
    });

    if (res.data) {
      setProjectId(res.data.active_project_id);
    } else {
      navigate("/404");
    }
  };

  if (!projectId) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  return <ProjectAppView projectId={projectId} />;
}

const Wrapper = () => {
  return (
    <PublicPageWrapper>
      <MainProjectAppPage />
    </PublicPageWrapper>
  );
};

export default Wrapper;
