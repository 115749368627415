import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Layout from "components/Layout";
import { Button } from "components/ui/button";
import { Plus } from "lucide-react";
import SecurePageWrapper from "components/SecurePageWrapper";
import { KnowledgeBaseItemCard } from "components/KnowledgeBaseItem";
import { Context, getContextsMe, postContexts } from "client";
import { useNavigate } from "react-router-dom";

const KnowledgeBasesPageWrapper = () => {
  return (
    <SecurePageWrapper>
      <Layout>
        <KnowledgeBasesPage />
      </Layout>
    </SecurePageWrapper>
  );
};

function KnowledgeBasesPage() {
  const navigate = useNavigate();
  const [knowledgeBases, setKnowledgeBases] = useState<Context[]>([]);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    getContextsMe().then((res) => setKnowledgeBases(res.data || []));
  }, []);

  const onCreateKnowledgeBase = async () => {
    setCreating(true);

    const res = await postContexts({
      body: {
        name: "New Knowledge Base",
      },
    });

    if (res.data) {
      navigate(`/knowledge-bases/${res.data.id}`);
    }

    setCreating(false);
  };

  return (
    <div>
      <div className="flex justify-between pb-5">
        <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0">
          Knowledge Bases
        </h2>
        <Button size="sm" disabled={creating} onClick={onCreateKnowledgeBase}>
          <Plus /> Create a knowledge base
        </Button>
      </div>
      <ListWrapper>
        {knowledgeBases.map((knowledgeBase) => (
          <KnowledgeBaseItemCard
            context={knowledgeBase}
            key={knowledgeBase.id}
            onClick={() => navigate(`/knowledge-bases/${knowledgeBase.id}`)}
          />
        ))}
      </ListWrapper>
    </div>
  );
}

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export default KnowledgeBasesPageWrapper;
