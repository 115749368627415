import React from "react";
import { useParams } from "react-router-dom";
import ProjectAppView from "components/ProjectAppView";

function ProjectAppViewPage() {
  const { projectId } = useParams();

  return <ProjectAppView projectId={projectId as string} />;
}

export default ProjectAppViewPage;
