import useProjectStore from "storage/ProjectStore";
import ComponentResultItemCard from "./ComponentResultItemCard";

const ComponentResultList = () => {
  const activeResult = useProjectStore((state) => state.activeResult);
  if (!activeResult) return <></>;

  activeResult.components.sort((a, b) => a.start_at - b.start_at);
  return (
    <div className="gap-5 flex flex-col pt-3">
      {activeResult.components.map((componentResult) => (
        <ComponentResultItemCard
          key={componentResult.component_id}
          componentResult={componentResult}
        />
      ))}
    </div>
  );
};

export default ComponentResultList;
