import { postProjectsByProjectIdRestore, Project } from "client";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { Badge } from "components/ui/badge";
import { Button } from "components/ui/button";
import { Drawer, DrawerContent, DrawerTrigger } from "components/ui/drawer";
import { TableCell, TableRow } from "components/ui/table";
import { shortId } from "lib/id";
import { formatSecs } from "lib/time";
import useProjectStore from "storage/ProjectStore";

const ProjectVersionRow = ({ item }: { item: Project }) => {
  const currentProjectId = useProjectStore((state) => state.activeProject?.id);
  const isCurrentVersion = item.id === currentProjectId;
  const results = useProjectStore((state) => state.results);
  const versionResults = results.filter(
    (result) => result.result.project_id === item.id,
  );
  const previewProjectUrl = `/projects/${item.id}?preview=true`;

  const restore = async () => {
    if (isCurrentVersion) return;

    const data = await postProjectsByProjectIdRestore({
      path: {
        project_id: item.id,
      },
    });

    if (data.data) {
      if (window.top) {
        window.top.location.replace(`/projects/${item.id}`);
      } else {
        window.location.replace(`/projects/${data.data.id}`);
      }
    }
  };

  return (
    <TableRow>
      <TableCell className="font-medium">
        <Drawer>
          <DrawerTrigger asChild>
            <Button size="sm" variant="link" className="p-0">
              #{shortId(item.id)}
            </Button>
          </DrawerTrigger>
          <DrawerContent className="h-[95vh] p-0">
            <iframe
              src={previewProjectUrl}
              className="w-full h-full"
              allow="top-navigation"
            />
          </DrawerContent>
        </Drawer>
      </TableCell>
      <TableCell>
        {isCurrentVersion ? (
          <Badge>Current</Badge>
        ) : (
          <Badge variant="outline">Old</Badge>
        )}
      </TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{formatSecs(item.created_at)}</TableCell>
      <TableCell>{versionResults.length}</TableCell>
      <TableCell>
        <div>
          {!isCurrentVersion && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button variant="secondary" size="sm">
                  Restore
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                  <AlertDialogDescription>
                    Do you want to restore this version?
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={restore}>
                    Yes, restore this version
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default ProjectVersionRow;
