import { Skeleton } from "components/ui/skeleton";
import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import useProjectStore, {
  useLastResult,
  useLoadProject,
} from "storage/ProjectStore";
import ProjectDisplay from "components/ProjectDisplay";
import { Separator } from "components/ui/separator";
import ProjectFlow from "components/ProjectFlow";
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";
import { TabsContent } from "@radix-ui/react-tabs";
import ComponentResultList from "components/ComponentResultList";
import { fromNowSecs } from "lib/time";
import { getDuration, isResultSuccess } from "lib/project_result";
import Status from "components/status";
import SecurePageWrapper from "components/SecurePageWrapper";
import { useLoadMyProviders } from "storage/ProviderStore";

const ProjectReviewResultPageWrapper = () => {
  return (
    <SecurePageWrapper>
      <ProjectReviewResultPage />
    </SecurePageWrapper>
  );
};

function ProjectReviewResultPage() {
  const { projectId } = useParams();
  const [searchParams, _setSearchParams] = useSearchParams();
  const resultId = searchParams.get("resultId");
  const { loading } = useLoadProject(projectId as string);
  useLoadMyProviders();
  const project = useProjectStore((state) => state.activeProject);
  const result = useLastResult(resultId as string);

  if (!project || !result || loading) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  const isSuccess = isResultSuccess(result);
  return (
    <div className="w-full h-screen overflow-hidden flex flex-col p-5">
      <div className="w-[95%] mx-auto h-full flex flex-col overflow-hidden">
        <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0">
          {project.name}
        </h2>
        <p className="text-sm text-muted-foreground">{project.description}</p>
        <Separator className="mb-5 mt-5" />
        <div className="pb-2 flex gap-2 items-center">
          <Status isSuccess={isSuccess} />
          <p className="text-sm text-muted-foreground">
            Run {fromNowSecs(result.result.created_at)} in {getDuration(result)}
            s
          </p>
        </div>
        <div className="w-full flex flex-grow-1 flex-1 h-full overflow-hidden">
          <div className="w-full flex-grow-1 flex-1 border overflow-hidden">
            <ProjectFlow project={project} readOnly />
          </div>
          <div className="w-full flex-grow-1 flex-1 p-5 border overflow-auto">
            <Tabs defaultValue="display" className="w-full">
              <TabsList>
                <TabsTrigger value="display">Display</TabsTrigger>
                <TabsTrigger value="components-result">
                  Component Results
                </TabsTrigger>
              </TabsList>
              <TabsContent value="display">
                <ProjectDisplay project={project} />
              </TabsContent>
              <TabsContent value="components-result">
                <ComponentResultList />
              </TabsContent>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectReviewResultPageWrapper;
