type BrandLogoProps = {
  brand: "openai" | "claude" | string;
  size?: number;
};

const BrandLogo = ({ brand, size }: BrandLogoProps) => {
  const finalSize = size || 24;

  if (brand.toLowerCase() === "openai") {
    return (
      <img
        src="/brands/openai-logomark.png"
        width={finalSize}
        height={finalSize}
        style={{ height: finalSize }}
      />
    );
  }

  return (
    <img
      src="/logo.png"
      width={finalSize}
      height={finalSize}
      style={{ height: finalSize }}
    />
  );
};

export default BrandLogo;
