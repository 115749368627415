import { PlayIcon } from "@radix-ui/react-icons";
import {
  postProjectsByProjectIdRun,
  Project,
  ProjectResultResponse,
} from "client";
import { ComponentDisplay } from "./components";
import { Button } from "components/ui/button";
import { fromNowSecs } from "lib/time";
import { useState } from "react";
import useProjectStore, { useLastResult } from "storage/ProjectStore";
import { getDuration } from "lib/project_result";
import { Loader2 } from "lucide-react";

type ProjectDisplayProps = {
  project: Project;
  resultId?: string;
  onCompleteRun?: (result: ProjectResultResponse) => void;
};

const ProjectDisplay = ({
  project,
  resultId,
  onCompleteRun,
}: ProjectDisplayProps) => {
  const componentTypeConfigs = useProjectStore(
    (state) => state.componentTypeConfigs,
  );
  const components = useProjectStore((state) => state.components);
  const inputs = useProjectStore((state) => state.inputs);
  const addResult = useProjectStore((state) => state.addResult);
  const setActiveResult = useProjectStore((state) => state.setActiveResult);
  const lastResult = useLastResult(resultId);
  const [runLoading, setRunLoading] = useState(false);

  const onRun = async () => {
    if (!project) return;
    setRunLoading(true);
    try {
      const result = await postProjectsByProjectIdRun({
        path: { project_id: project.id },
        body: inputs,
      });
      if (result.data) {
        addResult(result.data);
        setActiveResult(result.data);
        if (onCompleteRun) onCompleteRun(result.data);
      }
    } catch (err) {
      console.error("Cannot run", project.id);
    } finally {
      setRunLoading(false);
    }
  };

  return (
    <div className="gap-3 flex flex-col">
      {lastResult && (
        <p className="text-sm text-muted-foreground">
          Last Run: {fromNowSecs(lastResult.result.created_at)} in{" "}
          {getDuration(lastResult)}s
        </p>
      )}
      {project.graph.items
        .map((item) => components.find((c) => c.id === item.component_id))
        .filter(
          (component) =>
            component &&
            componentTypeConfigs.component_type_input.includes(
              component?.configuration.component_type,
            ),
        )
        .map((component) => {
          if (!component) return;
          return <ComponentDisplay key={component.id} component={component} />;
        })}
      <Button disabled={runLoading} size="sm" onClick={onRun}>
        {runLoading ? <Loader2 className="animate-spin" /> : <PlayIcon />} Run
      </Button>
      {project.graph.items
        .map((item) => components.find((c) => c.id === item.component_id))
        .filter(
          (component) =>
            component &&
            componentTypeConfigs.component_type_output.includes(
              component?.configuration.component_type,
            ),
        )
        .map((component) => {
          if (!component) return;
          return <ComponentDisplay key={component.id} component={component} />;
        })}
    </div>
  );
};

export default ProjectDisplay;
