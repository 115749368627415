import React from "react";
import styled from "styled-components";
import Layout from "components/Layout";
import { Button } from "components/ui/button";
import { Plus } from "lucide-react";
import SecurePageWrapper from "components/SecurePageWrapper";
import { ProviderItemCard } from "components/ProviderItem";
import CreateProviderDialog from "components/CreateProviderDialog";
import { useLoadMyProviders } from "storage/ProviderStore";

const ProvidersPageWrapper = () => {
  return (
    <SecurePageWrapper>
      <Layout>
        <ProvidersPage />
      </Layout>
    </SecurePageWrapper>
  );
};

function ProvidersPage() {
  const { providers, load } = useLoadMyProviders();

  return (
    <div>
      <div className="flex justify-between pb-5">
        <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0">
          Providers
        </h2>
        <CreateProviderDialog onCreateSuccess={load}>
          <Button size="sm">
            <Plus /> Create a Provider
          </Button>
        </CreateProviderDialog>
      </div>
      <ListWrapper>
        {providers.map((provider) => (
          <ProviderItemCard
            refetch={load}
            provider={provider}
            key={provider.id}
          />
        ))}
      </ListWrapper>
    </div>
  );
}

const ListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export default ProvidersPageWrapper;
