import { ReactNode, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";
import { Button } from "./ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Label } from "./ui/label";
import BrandLogo from "./BrandLogo";
import { postProviders, ProviderData, ProviderType } from "client";

const getDefaultProviderData = (
  providerType: ProviderType,
): ProviderData | undefined => {
  if (providerType === "OpenAI") {
    return {
      type: "OpenAI",
      openai_key: "your key",
      openai_url: "https://api.openai.com/v1",
    };
  }
};

type CreateProviderDialogProps = {
  children: ReactNode;
  onCreateSuccess: () => Promise<void>;
};

const CreateProviderDialog = ({
  children,
  onCreateSuccess,
}: CreateProviderDialogProps) => {
  const [open, setOpen] = useState(false);
  const [providerType, setProviderType] = useState<ProviderType | undefined>();
  const [creating, setCreating] = useState(false);

  const createProvider = async () => {
    if (!providerType) return;

    const providerData = getDefaultProviderData(providerType);
    if (!providerData) return;

    setCreating(true);

    const providerName = `My ${providerType} Provider`;

    const data = await postProviders({
      body: {
        provider_name: providerName,
        provider_type: providerType,
        provider_data: providerData,
      },
    });

    if (data.data) {
      onCreateSuccess();
      setOpen(false);
    }

    setCreating(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create a new provider</DialogTitle>
          <DialogDescription>
            A provider can be used as the engine for component runtime. You have
            the freedom to choose your AI vendor.
          </DialogDescription>
        </DialogHeader>
        <div>
          <Label>Provider</Label>
          <Select
            value={providerType}
            onValueChange={(val) => setProviderType(val as ProviderType)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder="Choose a provider" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="OpenAI">
                <div className="flex gap-2 items-center">
                  <BrandLogo size={16} brand={"OpenAI"} />
                  OpenAI
                </div>
              </SelectItem>
              <SelectItem value="claude" disabled>
                Claude (Anthropic)
              </SelectItem>
              <SelectItem value="llama" disabled>
                Llama (Meta)
              </SelectItem>
            </SelectContent>
          </Select>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">Cancel</Button>
          </DialogClose>
          <Button onClick={createProvider} disabled={creating || !providerType}>
            Create
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default CreateProviderDialog;
