import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { shortId } from "lib/id";
import BrandLogo from "./BrandLogo";
import useProviderStore from "storage/ProviderStore";

type SelectProviderProps = {
  providerId?: string;
  onChangeProvider: (providerId?: string) => void;
};

const SelectProvider = ({
  providerId,
  onChangeProvider,
}: SelectProviderProps) => {
  const providers = useProviderStore((state) => state.providers);
  const defaultProvider = providers.find(
    (provider) => provider.is_default_provider,
  );
  const val = providerId || defaultProvider?.id;

  const changeProvider = (providerId: string) => {
    if (providerId === defaultProvider?.id) {
      onChangeProvider();
    } else {
      onChangeProvider(providerId);
    }
  };

  return (
    <Select value={val} onValueChange={changeProvider}>
      <SelectTrigger className="w-full">
        <SelectValue placeholder="Choose a provider" />
      </SelectTrigger>
      <SelectContent>
        {providers.map((provider) => (
          <SelectItem key={provider.id} value={provider.id}>
            <div className="flex items-center gap-2">
              <BrandLogo brand={provider.provider_type} size={16} />{" "}
              {provider.provider_name} (#{shortId(provider.id)})
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default SelectProvider;
