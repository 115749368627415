import { cn } from "lib/utils";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import {
  deleteProvidersByProviderId,
  Provider,
  ProviderData,
  putProvidersByProviderId,
  SupportedComponents,
} from "client";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "components/ui/drawer";
import { useEffect, useState } from "react";
import ProviderDataView from "./ProviderData";
import BrandLogo from "components/BrandLogo";
import { shortId } from "lib/id";
import { Button } from "components/ui/button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";
import { Input } from "components/ui/input";
import { Badge } from "components/ui/badge";
import useProviderStore from "storage/ProviderStore";

type ProjectItemCardProps = {
  provider: Provider;
  refetch: () => Promise<void>;
} & React.ComponentProps<typeof Card>;

export function ProviderItemCard({
  provider,
  onClick,
  refetch,
  className,
  ...props
}: ProjectItemCardProps) {
  const supportedComponents =
    useProviderStore((state) => state.providerConfig[provider.provider_type]) ||
    [];
  const [innerProvider, setInnerProvider] = useState(provider);
  const [openDrawer, setOpenDrawer] = useState(false);
  const providerBy = provider.is_default_provider
    ? "Ez AI Provider"
    : "Your Provider";

  const onChangeProvider = (providerData: ProviderData) => {
    innerProvider.provider_data = providerData;
    setInnerProvider({ ...innerProvider });
  };

  const onChangeName = (name: string) => {
    console.log("OnChange", name);
    innerProvider.provider_name = name;
    setInnerProvider({ ...innerProvider });
  };

  const onSave = async () => {
    const data = await putProvidersByProviderId({
      path: {
        provider_id: innerProvider.id,
      },
      body: {
        provider_type: provider.provider_type,
        provider_data: innerProvider.provider_data,
        provider_name: innerProvider.provider_name,
      },
    });

    if (data.data) setOpenDrawer(false);
    refetch();
  };

  const onDelete = async () => {
    const data = await deleteProvidersByProviderId({
      path: {
        provider_id: innerProvider.id,
      },
    });

    if (data.data) {
      refetch();
      setOpenDrawer(false);
    }
  };

  return (
    <>
      <Card
        className={cn(
          "w-[380px] m-h-[150px] flex flex-col cursor-pointer",
          className,
        )}
        {...props}
        onClick={() => setOpenDrawer(true)}
      >
        <CardHeader className="flex flex-1 flex-row gap-2 items-center heigh-fit-content">
          <BrandLogo brand={provider.provider_type.toLowerCase()} />
          <div>
            <CardTitle>{provider.provider_name}</CardTitle>
            <CardDescription className="truncate">
              {providerBy} - #{shortId(provider.id)}
            </CardDescription>
          </div>
        </CardHeader>
        <CardContent>
          <div>
            <div className="space-y-1 w-full">
              {supportedComponents.map((supportedComponent) => (
                <Badge
                  variant="secondary"
                  key={supportedComponent}
                  className="m-1"
                >
                  {supportedComponent}
                </Badge>
              ))}
            </div>
          </div>
        </CardContent>
      </Card>
      <Drawer open={openDrawer} onOpenChange={setOpenDrawer}>
        <DrawerTrigger asChild>
          <></>
        </DrawerTrigger>
        <DrawerContent>
          <DrawerHeader>
            <div className="flex gap-2 items-center pb-2">
              <BrandLogo brand={provider.provider_type.toLowerCase()} />
              <div className="flex-1">
                <DrawerDescription>
                  {providerBy} - #{shortId(provider.id)}
                </DrawerDescription>
                <DrawerTitle>
                  {provider.is_default_provider && provider.provider_name}
                  {!provider.is_default_provider && (
                    <Input
                      value={innerProvider.provider_name}
                      onChange={(e) => onChangeName(e.currentTarget.value)}
                      placeholder="Typing Provider Name..."
                      className="w-full"
                    />
                  )}
                </DrawerTitle>
                {supportedComponents.map((supportedComponent) => (
                  <Badge key={supportedComponent} className="m-1">
                    {supportedComponent}
                  </Badge>
                ))}
              </div>
            </div>
            <ProviderDataView
              data={provider.provider_data}
              onChange={onChangeProvider}
              readOnly={provider.is_default_provider}
            />
          </DrawerHeader>
          {!provider.is_default_provider && (
            <DrawerFooter>
              <Button onClick={onSave}>Save</Button>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button variant="destructive">Delete</Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                    <AlertDialogDescription>
                      Do you want to remove {innerProvider.provider_name}?
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={onDelete}>
                      Continue Remove
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
              <DrawerClose asChild>
                <Button variant="outline">Close</Button>
              </DrawerClose>
            </DrawerFooter>
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
}
