import { SidebarProvider, SidebarTrigger } from "components/ui/sidebar";
import { AppSidebar } from "components/AppSidebar";
import useUIStore from "storage/UIStore";

export type LayoutProps = {
  children: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const isCollapse = useUIStore((state) => state.menuCollapse);
  const setCollapse = useUIStore((state) => state.setMenuCollapsible);
  return (
    <SidebarProvider
      open={!isCollapse}
      onOpenChange={(open) => setCollapse(!open)}
    >
      <AppSidebar />
      <main style={{ flex: 1 }}>
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <SidebarTrigger />
        </div>
        {children}
      </main>
    </SidebarProvider>
  );
};

export default Layout;
