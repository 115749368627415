import * as React from "react";

import { Textarea } from "components/ui/textarea";
import { Label } from "components/ui/label";
import {
  Component,
  EzEngineValue_StringEzValue,
  TextInputAttributes,
} from "client";
import useProjectStore from "storage/ProjectStore";

export type TextInputComponentDisplayProps = {
  component: Component;
  preview?: boolean;
};

export const TextInputComponentDisplay: React.FC<
  TextInputComponentDisplayProps
> = ({ component, preview }) => {
  const input = useProjectStore(
    (state) => state.inputs[component.component_name],
  ) as EzEngineValue_StringEzValue;
  const setInput = useProjectStore((state) => state.setInput);
  const activeResult = useProjectStore((state) => state.activeResult);

  React.useEffect(() => {
    if (!preview) {
      const inputRes = activeResult?.components?.find(
        (c) => c.component_id === component.id,
      )?.input;
      if (inputRes) {
        setInput(component.component_name, inputRes.input);
      }
    }
  }, [activeResult?.result?.id, preview]);

  const placeholder = (
    component.configuration.attributes as TextInputAttributes
  ).placeholder;
  const label =
    (component.configuration.attributes as TextInputAttributes).label ||
    component.component_name;

  const onChangeValue = (text: string) => {
    setInput(component.component_name, {
      type: "String",
      value: text,
    });
  };

  if (preview) {
    return (
      <div className="grid w-full gap-1.5">
        <Label htmlFor={component.id}>{label}</Label>
        <Textarea
          placeholder={placeholder || "Type something..."}
          id={component.id}
          rows={5}
        />
      </div>
    );
  }

  return (
    <div className="grid w-full gap-1.5">
      <Label htmlFor={component.id}>{label}</Label>
      <Textarea
        placeholder={placeholder || "Type something..."}
        id={component.id}
        value={input?.value}
        onChange={(e) => onChangeValue(e.currentTarget.value)}
        rows={5}
      />
    </div>
  );
};
