import * as React from "react";

import { Label } from "components/ui/label";
import {
  AudioInputAttributes,
  Component,
  File,
  getFilesByFileId,
} from "client";
import FileUploader from "components/FileUploader";
import useProjectStore from "storage/ProjectStore";
import AudioRecording from "components/AudioRecording";

export type AudioInputComponentDisplayProps = {
  component: Component;
  preview?: boolean;
};

export const AudioInputComponentDisplay: React.FC<
  AudioInputComponentDisplayProps
> = ({ component, preview }) => {
  const [file, setFile] = React.useState<File | undefined>();
  const input = useProjectStore(
    (state) => state.inputs[component.component_name],
  );
  const setInput = useProjectStore((state) => state.setInput);
  const activeResult = useProjectStore((state) => state.activeResult);

  React.useEffect(() => {
    if (input?.type === "Audio") {
      const fileId = input.file_id;
      loadFile(fileId);
    }
  }, [input]);

  const loadFile = async (fileId: string) => {
    const fileRes = await getFilesByFileId({
      path: { file_id: fileId },
    });
    setFile(fileRes?.data);
  };

  const onUploadComplete = (file: File) => {
    setInput(component.component_name, {
      type: "Audio",
      file_id: file.id,
    });
  };

  React.useEffect(() => {
    if (!preview) {
      const inputRes = activeResult?.components?.find(
        (c) => c.component_id === component.id,
      )?.input;
      if (inputRes) {
        const input = inputRes.input;
        setInput(component.component_name, input);
      }
    }
  }, [activeResult?.result?.id, preview]);

  const label =
    (component.configuration.attributes as AudioInputAttributes).label ||
    component.component_name;
  const voiceMode = (component.configuration.attributes as AudioInputAttributes)
    .allow_voice;

  if (preview) {
    return (
      <div className="grid w-full gap-1.5">
        <Label htmlFor={component.id}>{label}</Label>
        <div className="w-full">
          {!voiceMode && (
            <FileUploader
              readOnly
              accept="audio/*"
              onCompleteUpload={console.log}
            />
          )}
          {voiceMode && (
            <AudioRecording onCompleteFile={console.log} readOnly />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="grid w-full gap-1.5">
      <Label htmlFor={component.id}>{label}</Label>
      <div className="w-full">
        {!voiceMode && (
          <FileUploader
            accept="audio/*"
            onCompleteUpload={onUploadComplete}
            fileDefault={file}
          />
        )}
        {voiceMode && (
          <AudioRecording fileRes={file} onCompleteFile={onUploadComplete} />
        )}
      </div>
    </div>
  );
};
