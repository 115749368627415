import {
  Context,
  deleteProjectsByProjectIdContextsByContextId,
  getProjectsByProjectIdContexts,
  Project,
} from "client";
import { Button } from "components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { shortId } from "lib/id";
import { fromNowSecs } from "lib/time";
import { Brain } from "lucide-react";
import { useEffect, useState } from "react";
import AttachKnowledge from "./AttachKnowledge";
import { Drawer, DrawerContent, DrawerTrigger } from "components/ui/drawer";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/alert-dialog";

const ProjectKnowledgeBases = ({ project }: { project: Project }) => {
  const [contexts, setContexts] = useState<Context[]>([]);

  const load = async () => {
    if (!project) return;

    const res = await getProjectsByProjectIdContexts({
      path: {
        project_id: project.id,
      },
    });

    if (res.data) {
      setContexts(res.data);
    }
  };

  useEffect(() => {
    load();
  }, [project.id]);

  return (
    <div className="w-full flex-1 h-full overflow-auto">
      <div className="pb-2 pt-2">
        {project && (
          <AttachKnowledge
            project={project}
            afterAttach={load}
            selectedContextIds={contexts.map((context) => context.id)}
          >
            <Button variant="secondary" size="sm">
              <Brain /> Attach new Knowledge
            </Button>
          </AttachKnowledge>
        )}
      </div>
      <div className="w-full flex flex-grow-1 flex-1 h-full overflow-auto">
        <Table>
          <TableCaption>Knowledge bases of {project.name} project</TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Id</TableHead>
              <TableHead>Name</TableHead>
              <TableHead className="w-[200px]">Created at</TableHead>
              <TableHead className="w-[120px]"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {contexts.map((item) => (
              <ProjectKnowledgeBaseItem
                item={item}
                key={item.id}
                project={project}
                afterRemove={load}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

type ProjectKnowledgeBaseItemProps = {
  project: Project;
  item: Context;
  afterRemove?: () => void | Promise<void>;
};

const ProjectKnowledgeBaseItem = ({
  item,
  project,
  afterRemove,
}: ProjectKnowledgeBaseItemProps) => {
  const url = `${window.origin}/knowledge-bases/${item.id}`;

  const remove = async () => {
    const res = await deleteProjectsByProjectIdContextsByContextId({
      path: {
        project_id: project.id,
        context_id: item.id,
      },
    });

    if (res.data && afterRemove) afterRemove();
  };
  return (
    <TableRow>
      <TableCell>
        <Drawer>
          <DrawerTrigger asChild>
            <Button variant="link" asChild>
              <a>#{shortId(item.id)}</a>
            </Button>
          </DrawerTrigger>
          <DrawerContent className="h-[90vh]">
            <iframe src={url} className="w-full h-full" />
          </DrawerContent>
        </Drawer>
      </TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{fromNowSecs(item.created_at)}</TableCell>
      <TableCell>
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button size="sm" variant="destructive">
              Un-attach
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>
                Un-attach knowledge space out of project
              </AlertDialogTitle>
              <AlertDialogDescription>
                Do you want to un-attach knowledge space
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel asChild>
                <Button variant="outline">Cancel</Button>
              </AlertDialogCancel>
              <Button variant="destructive" onClick={remove}>
                Sure, Un-attach
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </TableCell>
    </TableRow>
  );
};

export default ProjectKnowledgeBases;
