import { cn } from "lib/utils";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "components/ui/card";
import { Context } from "client";
import { fromNowSecs } from "lib/time";

type ProjectItemCardProps = {
  context: Context;
} & React.ComponentProps<typeof Card>;

export function KnowledgeBaseItemCard({
  context,
  onClick,
  className,
  ...props
}: ProjectItemCardProps) {
  return (
    <>
      <Card
        className={cn(
          "w-[380px] m-h-[150px] flex flex-col cursor-pointer",
          className,
        )}
        {...props}
        onClick={onClick}
      >
        <CardHeader className="heigh-fit-content">
          <CardTitle>{context.name}</CardTitle>
          <CardDescription className="truncate">
            {fromNowSecs(context.created_at)}
          </CardDescription>
        </CardHeader>
        <CardContent></CardContent>
      </Card>
    </>
  );
}
