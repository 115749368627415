import { RocketIcon } from "@radix-ui/react-icons";
import { postProjectsByProjectIdPublic, Project } from "client";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Input } from "components/ui/input";
import { Separator } from "./ui/separator";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import useProjectStore from "storage/ProjectStore";

function ProjectPublish() {
  const project = useProjectStore((state) => state.activeProject);
  const setActiveProject = useProjectStore((state) => state.setActiveProject);

  if (!project) return <></>;

  const url = `${window.location.origin}/app/${project.main_project_id}`;
  const value = project.is_public ? "Access" : "No Access";

  const onCopy = () => {
    navigator.clipboard.writeText(url);
  };

  const onChangeValue = async (value: string) => {
    if (!project) return;

    const isPublic = value === "Access";
    const data = await postProjectsByProjectIdPublic({
      path: {
        project_id: project.id,
      },
      body: isPublic,
    });

    if (data) {
      setActiveProject({
        ...project,
        is_public: isPublic,
      });
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm">
          <RocketIcon /> Share & Publish
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Publish Project</DialogTitle>
          <DialogDescription>
            Share and publish your project to the internet.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="flex justify-between items-center">
            <div className="text-sm font-medium">Anyone with the link</div>
            <div>
              <Select value={value} onValueChange={onChangeValue}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Access">Access</SelectItem>
                  <SelectItem value="No Access">No Access</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <Separator />
          <div className="grid grid-cols-4 items-center gap-4">
            <Input
              id="shared-link"
              value={url}
              className="col-span-3"
              disabled={!project.is_public}
            />
            <Button
              size="sm"
              variant="outline"
              onClick={onCopy}
              disabled={!project.is_public}
            >
              Copy
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ProjectPublish;
