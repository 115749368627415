import { ProviderData } from "client";
import OpenAIProvider from "./OpenAIProvider";

type Props = {
  data: ProviderData;
  onChange: (data: ProviderData) => void;
  readOnly?: boolean;
};

const ProviderDataView = ({ data, onChange, readOnly }: Props) => {
  if (data.type === "OpenAI") {
    return (
      <OpenAIProvider data={data} onChange={onChange} readOnly={readOnly} />
    );
  }

  return <div>Hello</div>;
};

export default ProviderDataView;
