import React, { useEffect, useState } from "react";
import SecurePageWrapper from "components/SecurePageWrapper";
import {
  Context,
  ContextText,
  deleteContextsByContextId,
  deleteContextTextsByTextId,
  getContextsByContextId,
  getContextsByContextIdTexts,
  postContextsByContextIdContextTexts,
  putContextsByContextId,
} from "client";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "components/ui/skeleton";
import { fromNowSecs } from "lib/time";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { Brain, Edit, Plus, Trash } from "lucide-react";
import { Button } from "components/ui/button";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { shortId } from "lib/id";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { Textarea } from "components/ui/textarea";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";
import { Badge } from "components/ui/badge";
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "components/ui/alert-dialog";

const KnowledgeBasePageWrapper = () => {
  return (
    <SecurePageWrapper>
      <KnowledgeBasePage />
    </SecurePageWrapper>
  );
};

function KnowledgeBasePage() {
  const navigate = useNavigate();
  const { knowledgeBaseId } = useParams();
  const [knowledgeBase, setKnowledgeBase] = useState<Context | undefined>();
  const [texts, setTexts] = useState<ContextText[]>([]);
  const [adding, setAdding] = useState(false);
  const [addingText, setAddingText] = useState("");
  const [openAddContent, setOpenAddContent] = useState(false);

  const addTextIntoContext = async () => {
    setAdding(true);

    const res = await postContextsByContextIdContextTexts({
      path: {
        context_id: knowledgeBaseId as string,
      },
      body: {
        content: addingText,
      },
    });

    if (res.data) {
      load();
      setAddingText("");
      setOpenAddContent(false);
    }
    setAdding(false);
  };

  const onChangeName = (name: string) => {
    if (!knowledgeBase) return;

    knowledgeBase.name = name;
    setKnowledgeBase({ ...knowledgeBase });
  };

  const update = async () => {
    if (!knowledgeBase) return;

    await putContextsByContextId({
      path: {
        context_id: knowledgeBase.id,
      },
      body: {
        name: knowledgeBase.name,
      },
    });
  };

  const load = () => {
    getContextsByContextId({
      path: { context_id: knowledgeBaseId as string },
    }).then((res) => setKnowledgeBase(res.data));
    getContextsByContextIdTexts({
      path: { context_id: knowledgeBaseId as string },
    }).then((res) => setTexts(res.data || []));
  };

  const deleteKnowledgeSpace = async () => {
    if (!knowledgeBase) return;

    const res = await deleteContextsByContextId({
      path: {
        context_id: knowledgeBase.id,
      },
    });

    if (res.data) {
      navigate("/knowledge-bases");
    }
  };
  useEffect(() => {
    load();
  }, [knowledgeBaseId]);

  if (!knowledgeBase) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  return (
    <div className="w-full h-screen flex flex-col p-2 overflow-hidden">
      <div className="flex items-center">
        <div className="p-2">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild className="cursor-pointer">
                <Link to="/knowledge-bases">
                  <Brain size={35} />
                </Link>
              </TooltipTrigger>
              <TooltipContent className="bg-black text-white border-0">
                <p>Knowledge Base</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
        <div className="flex-1">
          <h2 className="scroll-m-20 text-2xl font-semibold tracking-tight first:mt-0 items-center">
            {knowledgeBase.name}{" "}
            <Dialog>
              <DialogTrigger>
                <Button variant="ghost" size="icon">
                  <Edit />
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Edit Knowledge Base</DialogTitle>
                  <DialogDescription>
                    Edit name of Knowledge base
                  </DialogDescription>
                </DialogHeader>
                <div>
                  <Label>Name</Label>
                  <Input
                    placeholder="Typing knowledge base name"
                    value={knowledgeBase.name}
                    onChange={(e) => onChangeName(e.currentTarget.value)}
                  />
                </div>
                <DialogFooter>
                  <DialogClose>
                    <Button variant="outline">Cancel</Button>
                  </DialogClose>
                  <DialogClose>
                    <Button onClick={update}>Save</Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </h2>
          <p className="text-sm text-muted-foreground">
            #{shortId(knowledgeBase.id)} -{" "}
            {fromNowSecs(knowledgeBase.created_at)}
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <AlertDialog>
            <AlertDialogTrigger>
              <Button variant="destructive">
                <Trash /> Delete knowledge space
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Delete "{knowledgeBase.name}" knowledge space!
                </AlertDialogTitle>
                <AlertDialogDescription>
                  Are you sure you want to delete this Knowledge Space? <br />
                  Related AI projects associated with this Knowledge Space will
                  lose access.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel asChild>
                  <Button variant="outline">Cancel</Button>
                </AlertDialogCancel>
                <Button variant="destructive" onClick={deleteKnowledgeSpace}>
                  Sure, delete this
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
          <Dialog open={openAddContent} onOpenChange={setOpenAddContent}>
            <DialogTrigger asChild>
              <Button>
                <Plus /> Add content
              </Button>
            </DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Add content</DialogTitle>
                <DialogDescription>
                  The knowledge is the source of truth for the AI project.
                </DialogDescription>
              </DialogHeader>
              <div>
                <Textarea
                  placeholder="Typing text"
                  rows={10}
                  value={addingText}
                  onChange={(e) => setAddingText(e.currentTarget.value)}
                />
              </div>
              <DialogFooter>
                <Button disabled={adding} onClick={addTextIntoContext}>
                  <Plus /> Add to knowledge
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
      <div className="h-[100%] overflow-auto">
        <Table>
          <TableCaption>
            A list of sources that have been used as knowledge for your AI
            projects.
          </TableCaption>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">Id</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Content</TableHead>
              <TableHead className="w-[200px]">Added At</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {texts.map((text) => (
              <KnowledgeBaseTextItem
                text={text}
                key={text.id}
                afterDelete={load}
              />
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

type KnowledgeBaseTextItemProps = {
  text: ContextText;
  afterDelete: () => Promise<void> | void;
};

const KnowledgeBaseTextItem = ({
  text,
  afterDelete,
}: KnowledgeBaseTextItemProps) => {
  const [deleteOpen, setDeleteOpen] = useState(false);

  const deleteMemoryText = async (knowledgeTextId: string) => {
    const res = await deleteContextTextsByTextId({
      path: {
        text_id: knowledgeTextId,
      },
    });

    if (res.data) {
      afterDelete();
      setDeleteOpen(false);
    }
  };

  return (
    <TableRow key={text.id}>
      <TableCell>{shortId(text.id)}</TableCell>
      <TableCell>Text</TableCell>
      <TableCell>
        {text.status === "Indexing" && (
          <Badge variant="destructive">Learning...</Badge>
        )}
        {text.status === "Enable" && <Badge>Available</Badge>}
        {text.status === "Disable" && <Badge variant="outline">Disable</Badge>}
      </TableCell>
      <TableCell className="whitespace-pre-wrap">
        {text.content.length > 200
          ? `${text.content.substring(0, 200)}...`
          : text.content}
        {text.content.length > 200 && (
          <Dialog>
            <DialogTrigger>
              <Button variant="link" size="sm" color="blue">
                View
              </Button>
            </DialogTrigger>
            <DialogContent className="w-[80vw] h-[70vh]">
              <div className="whitespace-pre-wrap overflow-auto">
                {text.content}
              </div>
            </DialogContent>
          </Dialog>
        )}
      </TableCell>
      <TableCell>{fromNowSecs(text.created_at)}</TableCell>
      <TableCell>
        {text.status !== "Indexing" && (
          <AlertDialog open={deleteOpen} onOpenChange={setDeleteOpen}>
            <AlertDialogTrigger asChild>
              <Button variant="ghost" size="icon">
                <Trash color="red" />
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Delete text memory</AlertDialogTitle>
                <AlertDialogDescription>
                  This action will remove the text memory and cannot undo. Are
                  you sure to delete this?
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel asChild>
                  <Button variant="outline">Cancel</Button>
                </AlertDialogCancel>
                <Button
                  variant="destructive"
                  onClick={() => deleteMemoryText(text.id)}
                >
                  Sure, delete text memory
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        )}
      </TableCell>
    </TableRow>
  );
};

export default KnowledgeBasePageWrapper;
