import { ProviderData_OpenAIProvider } from "client";
import { Input } from "components/ui/input";
import { Label } from "components/ui/label";

type Props = {
  data: ProviderData_OpenAIProvider;
  onChange: (data: ProviderData_OpenAIProvider) => void;
  readOnly?: boolean;
};

const OpenAIProvider = ({ data, onChange, readOnly }: Props) => {
  const onChangeOpenAIUrl = (url: string) => {
    data.openai_url = url;
    onChange({ ...data });
  };

  const onChangeKey = (key: string) => {
    data.openai_key = key;
    onChange({ ...data });
  };

  return (
    <div className="flex gap-2">
      <div className="flex-1 flex flex-col gap-2">
        <div>
          <Label htmlFor="key">OpenAI Key</Label>
          <Input
            id="key"
            value={data.openai_key}
            readOnly={readOnly}
            onChange={(e) => onChangeKey(e.currentTarget.value)}
          />
        </div>
        <div>
          <Label htmlFor="key">OpenAI Url</Label>
          <Input
            id="key"
            value={data.openai_url}
            readOnly={readOnly}
            onChange={(e) => onChangeOpenAIUrl(e.currentTarget.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default OpenAIProvider;
