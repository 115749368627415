import { Skeleton } from "components/ui/skeleton";
import React, { useEffect, useState } from "react";
import useProjectStore, {
  useLoadProject,
  useProjectResults,
} from "storage/ProjectStore";
import ProjectDisplay from "components/ProjectDisplay";
import { Separator } from "components/ui/separator";
import { v4 } from "uuid";
import { useSearchParams } from "react-router-dom";
import { Button } from "./ui/button";
import BrandLogo from "./BrandLogo";
import { ProjectComponentResult, ProjectResultResponse } from "client";
import { DateFormat, formatSecs, fromNowSecs } from "lib/time";
import { shortId } from "lib/id";
import { cn } from "lib/utils";

type ProjectAppViewProps = {
  projectId: string;
};

const ProjectAppView = ({ projectId }: ProjectAppViewProps) => {
  const { loading } = useLoadProject(projectId);
  const project = useProjectStore((state) => state.activeProject);
  const results = useProjectResults();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchResultId = searchParams.get("resultId");

  const onChangeResult = (result?: ProjectResultResponse) => {
    setSearchParams((params) => {
      if (result) {
        params.set("resultId", result.result.id);
      } else {
        params.delete("resultId");
      }
      return params;
    });
  };

  if (!project || loading) {
    return <Skeleton className="w-[100px] h-[20px] rounded-full" />;
  }

  return (
    <div className="w-full flex g-2 h-screen overflow-hidden">
      <div className="w-[250px] bg-gray-200 hidden sm:block md:block overflow-auto p-5">
        <div className="flex items-center gap-2 pb-2">
          <BrandLogo brand="ez-ai" size={36} />
          <h4 className="scroll-m-20 text-xl font-semibold tracking-tight text-center">
            Ez AI
          </h4>
        </div>
        <Button
          className="w-full mb-2"
          size="sm"
          onClick={() => onChangeResult()}
        >
          New Run
        </Button>
        {results.map((result) => (
          <ResultItem
            key={result.result.id}
            onClick={onChangeResult}
            result={result}
            isActive={result.result.id === searchResultId}
          />
        ))}
      </div>
      <div className="w-full flex flex-col flex-1 p-5 overflow-auto">
        <div className="w-[95%] md:w-[80%] mx-auto">
          <h2 className="scroll-m-20 pb-2 text-3xl font-semibold tracking-tight first:mt-0">
            {project.name}
          </h2>
          <p className="text-sm text-muted-foreground">{project.description}</p>
          <Separator className="mb-5 mt-5" />
          <ProjectDisplay
            project={project}
            resultId={searchResultId || v4()}
            onCompleteRun={onChangeResult}
          />
        </div>
      </div>
    </div>
  );
};

type ResultItemProps = {
  result: ProjectResultResponse;
  onClick: (result: ProjectResultResponse) => void;
  isActive?: boolean;
};

const ResultItem = ({ result, onClick, isActive }: ResultItemProps) => {
  const inputComponent = result.components.sort(
    (a, b) => a.end_at - b.end_at,
  )[0]?.input;

  let title = formatSecs(result.result.created_at, DateFormat.Short);
  if (inputComponent && inputComponent.input.type === "String") {
    title = inputComponent.input.value;
  }

  return (
    <div
      className={cn(
        "hover:bg-gray-300 p-2 cursor-pointer rounded-md items-center",
        isActive ? "bg-gray-300" : "",
      )}
      onClick={() => onClick(result)}
    >
      <small className="text-sm font-medium leading-none">{title}</small>
      <p className="text-sm text-muted-foreground">
        {fromNowSecs(result.result.created_at)} (#{shortId(result.result.id)})
      </p>
    </div>
  );
};

export default ProjectAppView;
