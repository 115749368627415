import * as React from "react";

import { AuthStepState, useLoadTempUser, useLoadUser } from "storage/AuthStore";
import { Loader2 } from "lucide-react";

export type PublicPageWrapperProps = {
  children: React.ReactNode;
};

const PublicPageWrapper = ({ children }: PublicPageWrapperProps) => {
  const { authStep } = useLoadTempUser();

  if (authStep === AuthStepState.Loading) {
    return (
      <div>
        <Loader2 className="mr-2 h-4 w-4 animate-spin" /> Verifying...
      </div>
    );
  }

  if (authStep == AuthStepState.UnAuthenticate) {
    return (
      <div>
        Something went wrong! Please retry in few minutes or contact support
        team.
      </div>
    );
  }

  return <>{children}</>;
};

export default PublicPageWrapper;
