import {
  Context,
  getContextsMe,
  postContexts,
  postProjectsByProjectIdContexts,
  Project,
} from "client";
import { Button } from "components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "components/ui/dialog";
import { useEffect, useState } from "react";

type AttachKnowledgeProps = {
  project: Project;
  selectedContextIds: string[];
  children: React.ReactNode;
  afterAttach?: () => void | Promise<void>;
};

const AttachKnowledge = ({
  children,
  project,
  afterAttach,
  selectedContextIds,
}: AttachKnowledgeProps) => {
  const [contexts, setContexts] = useState<Context[]>([]);
  const [open, setOpen] = useState(false);
  const [creating, setCreating] = useState(false);

  const load = async () => {
    const res = await getContextsMe();
    if (res.data) {
      setContexts(res.data);
    }
  };

  const attachKnowledge = async (contextId: string) => {
    const res = await postProjectsByProjectIdContexts({
      path: {
        project_id: project.id,
      },
      body: contextId,
    });

    if (res.data) {
      setOpen(false);
      if (afterAttach) afterAttach();
    }
  };

  const createNewKnowledge = async () => {
    setCreating(true);

    const res = await postContexts({
      body: {
        name: `${project.name} knowledge`,
      },
    });

    if (res.data) {
      attachKnowledge(res.data.id);
    }

    setCreating(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Attach knowledge to project</DialogTitle>
          <DialogDescription>
            Attach existing knowledge into the project or create new knowledge.
            Choose the knowledge below or create new.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-5">
          <div className="flex flex-col gap-2">
            {contexts
              .filter((context) => !selectedContextIds.includes(context.id))
              .map((context) => (
                <div
                  className="text-sm font-medium leading-none group relative cursor-pointer p-3 bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-700 rounded-md shadow-sm hover:shadow-md hover:scale-105 transition-all duration-200 ease-in-out"
                  onClick={() => attachKnowledge(context.id)}
                >
                  {context.name}
                </div>
              ))}
          </div>
        </div>
        <DialogFooter>
          <Button onClick={createNewKnowledge} disabled={creating}>
            Create new knowledge and Attach
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default AttachKnowledge;
