import { getMainProjectsByMainProjectIdVersions, Project } from "client";
import {
  Table,
  TableBody,
  TableCaption,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";
import { useEffect, useState } from "react";
import useProjectStore from "storage/ProjectStore";
import ProjectVersionRow from "./ProjectVersionRow";

const ProjectVersions = () => {
  const activeProject = useProjectStore((state) => state.activeProject);
  const [versions, setVersions] = useState<Project[]>([]);

  useEffect(() => {
    if (activeProject?.main_project_id) {
      getMainProjectsByMainProjectIdVersions({
        path: {
          main_project_id: activeProject.main_project_id,
        },
      }).then((data) => setVersions(data?.data || []));
    }
  }, [activeProject?.main_project_id]);

  return (
    <Table>
      <TableCaption>Your edit history for this project.</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">Version Id</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Created at</TableHead>
          <TableHead>Runs</TableHead>
          <TableHead className="w-[120px]"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {versions.map((version) => (
          <ProjectVersionRow item={version} key={version.id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ProjectVersions;
