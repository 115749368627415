const KEY = "ez-ai-temp-id";

export const get = () =>
  typeof window !== "undefined" ? localStorage.getItem(KEY) : undefined;
export const set = (tempUserId: string) =>
  typeof window !== "undefined"
    ? localStorage.setItem(KEY, tempUserId)
    : undefined;
export const remove = () =>
  typeof window !== "undefined" ? localStorage.removeItem(KEY) : undefined;
