import { create } from "zustand";
import {
  getProvidersSupportComponentType,
  getProvidersMyProviders,
  Provider,
  SupportedComponents,
} from "client";
import { useEffect } from "react";

type ProviderState = {
  providers: Provider[];
  setProviders: (providers: Provider[]) => void;
  providerConfig: { [key: string]: Array<SupportedComponents> };
  setProviderConfig: (data: {
    [key: string]: Array<SupportedComponents>;
  }) => void;
};

const useProviderStore = create<ProviderState>((set, get) => ({
  providers: [],
  setProviders: (providers) => set({ providers }),
  providerConfig: {},
  setProviderConfig: (data) => set({ providerConfig: data }),
}));

export default useProviderStore;

export const useLoadMyProviders = () => {
  const providers = useProviderStore((state) => state.providers);
  const setProviderConfig = useProviderStore(
    (state) => state.setProviderConfig,
  );
  const setProviders = useProviderStore((state) => state.setProviders);

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const providersRes = await getProvidersMyProviders();
    if (providersRes.data) {
      setProviders(providersRes.data);
    }

    const res = await getProvidersSupportComponentType();
    if (res.data) {
      setProviderConfig(res.data);
    }
  };

  return {
    providers,
    load,
  };
};
