import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(utc);
dayjs.extend(relativeTime);

export const DateFormat = {
  Normal: "D MMM YYYY, HH:mm:ss",
  Short: "D MMM",
};

export const convertSecondsToDay = (secs: number) => {
  return dayjs.unix(secs);
};

export const fromNowSecs = (secs: number) => {
  return convertSecondsToDay(secs).fromNow();
};

export const formatSecs = (secs: number, format = DateFormat.Normal) => {
  return convertSecondsToDay(secs).format(format);
};
